@use "sass:math";
///////////////
// Text Color
///////////////

.tc-white {
  color: white;
}

.tc-black {
  color: $black;
}

.tc-gray {
  color: $dark-gray;
}

.tc-red {
  color: $red;
}

.tc-green {
  color: $green;
}

.tc-pink {
  color: $pink;
}

.tc-slate {
  color: $slate;
}

.text-center {
  text-align: center;
}

.text-small {
  font-size: math.div(14,16)rem;
}

.ta-center {
  text-align: center;
}

.no-text-decoration {
  text-decoration: none;
}

.tw-normal {
  font-weight: normal;
}

.tw-bold {
  font-weight: bold;
}
