@use "sass:math";

.contribution {
  background: #FFF;
  border: 1px solid $border-gray;
  border-radius: 3px;

  h4 {
    font-size: math.div(21,16)rem;
  }


  &.deferred {
    h4,
    div,
    p,
    button {
      color: $gray !important;
    }
  }
}

.pull-panel {
  border: 1px solid $border-gray;
  border-radius: 3px;
  padding: 20px;
  margin-top: 20px;

  p {
    margin-top: 0;
  }

}

.contributions__due-in {
  margin-left: 0;
  margin-top: 10px;
  display: block;
  height: auto;
  width: 100%;
  text-align: center;
  @include breakpoint($mid-breakpoint) {
    margin-top: 0;
    margin-left: 10px;
    display: table;
    height: 64px;
    width: auto;
    text-align: left;
  }
}

.contributions__due-in div {
  display: block;
  @include breakpoint($mid-breakpoint) {
    vertical-align: middle;
    display: table-cell;
  }
}

.contributions__app-image {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative;
  top: 6px;
  border-radius: 5px;
  overflow: hidden;
}

.contributions__app-image img {
  width: 40px;
  height: 40px;
}

.show-additional {
  font-size: 1rem;
  border-radius: $global-border-radius;
  border: 1px solid $border-gray;
  background: $white;
  color: $gray;
  &:hover {
    color: $black;
    background: $lightest-gray;
  }
}

.contribution {
  &.milestone-contribution {
    background: none;
    border: none;

    h5 {
      font-size: 1rem;
      line-height: 30px;
    }
    p {
      color: $warm-grey;
      font-size: math.div(14,16)rem;
      line-height: 1.2em;
      margin: 0
    }

  }
}

.milestone-dot-holder {
  width: 15%;
  @include breakpoint($mid-breakpoint) {
    width: 10%;
  }
}

.milestone-description {
  width: 85%;
  @include breakpoint($mid-breakpoint) {
    width: 90%;
  }

}

.milestone-dot {
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: math.div(18,16)rem;
  border: 1px solid $gray;
  border-radius: 50%;
  display: inline-block;
  background: #FFF;

  &.filled {
    background: $gray;
  }

  &.green {
    border-color: $green;
    color: $green;
    text-align: center;
  }

}

.compensation {
  &.compensation-mini {
    font-size: math.div(14,16)rem;
    width: 100%;
    text-align: center;
    border: 1px solid $green;
    border-radius: $global-border-radius;
    line-height: 1em;
    float: right;
    margin-top: 10px;

    @include breakpoint($mid-breakpoint) {
      width: auto;
      margin-top: auto;
    }

    &.green {
      color: $green;
      .help-link {
        color: $green;
        border-color: $green;
      }
    }
    &.red {
      border-color: $red;
      color: $red;

      .help-link {
        color: $red;
        border-color: $red;
      }
    }
    &.pink {
      border-color: $pink;
      color: $pink;

      .help-link {
        color: $pink;
        border-color: $pink;
      }
    }

    h6 {
      margin: 0;
      font-weight: normal;
      font-size: math.div(12,16)rem;
    }

    .deferred & {
      h4,
      div,
      p,
      button {
        color: $gray;
      }
      border-color: $gray;
      h6,
      .help-link {
        color: $gray;
        border-color: $gray;
      }
    }
  }
}
