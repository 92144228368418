.go-back-link {
  font-size: 0.9rem;
  background: none;
  border: none;
  color: #162D6D;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
  
  &:focus,
  &:active {
    outline: none;
    text-decoration: underline;
  }
  & svg {
    margin-right: 6px;
  }
}