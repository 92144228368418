.auth-content {
  @include span(10 of 10);
  padding-left: 30px;
  padding-right: 30px;
  @include breakpoint($high-breakpoint) {
    @include span(4 of 10);
    padding-left: 0;
    padding-right: 0;
    float: none;
    margin: auto;
  }
}

.login-error--container {
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 0.5rem !important;
}

.login-error--message {
  font-size: 12px;
  font-color: white;
}

.login-error--close-btn {
  background: transparent;
  padding: 1rem;
  color: #fff;
  & svg {
    fill: currentColor;
    & path {
      fill: currentColor;
    }
  }
}
