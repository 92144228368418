ng-combo-date-picker {
  select {
    -webkit-appearance: button;
    -moz-appearance: button;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -webkit-padding-end: 20px;
    -moz-padding-end: 20px;
    -webkit-padding-start: 2px;
    -moz-padding-start: 2px;
    background: $white;
    border: 1px solid $light-gray;
    color: #555;
    font-size: inherit;
    margin: 0;
    overflow: hidden;
    padding: 2px 2px 2px 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    border-radius: 0;
    font-size: 1rem;
  }
  .monthField {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-right: 0;
    width: 45%;
  }
  .dayField {
    border-radius: 0;
    width: 20%;
  }
  .yearField {
    border-left: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    width: 35%;
  }
}
