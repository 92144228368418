
.how-you-help__image {
  @include span(10 of 10);
  margin-bottom: 20px;
  @include breakpoint($mid-breakpoint) {
    @include span(3 of 10);
    margin-bottom: 0;
  }
}

.how-you-help__content {
  @include span(last 10 of 10);
  @include breakpoint($mid-breakpoint) {
    @include span(last 7 of 10);
  }
}

.how-you-help__content__body ol,
.how-you-help__content__body ul {
  padding-left: 25px;
}
