.side-by-side.left {
  float: right;
  @include span(1 of 16);
}

.side-by-side.right {
  float: right;
  @include span(last 15 of 16);
}

.informed-consent-navigation.middle button.previous {
  @include span(10 of 10);
  float: right;
  @include breakpoint($high-breakpoint) {
    @include span(5 of 10);
    float: right;
  }
}

.informed-consent-navigation.middle button.continue {
  @include span(10 of 10);
  margin-bottom: 10px;
  float: right;
  @include breakpoint($high-breakpoint) {
    @include span(last 5 of 10);
    margin-bottom: 0;
    float: right;
  }
}

.informed-consent-navigation.final .previous {
  margin-top: 10px;
  @include breakpoint($high-breakpoint) {
    float: right;
    width: 13%;
    height: 60px;
    margin-right: 3%;
    overflow: hidden;
    padding: 0;
    margin-top: 0;
  }
}

.informed-consent-navigation.final .previous .carrot {
  display: none;
  @include breakpoint($high-breakpoint) {
    display: block;
  }
}

.informed-consent-navigation.final .previous .text {
  display: block;
  @include breakpoint($high-breakpoint) {
    display: none;
  }
}

.informed-consent-navigation.final .continue {
  @include breakpoint($high-breakpoint) {
    float: right;
    width: 84%;
    padding-left: 0;
    padding-right: 0;
  }
}

.scrollbox {
  overflow-y: auto;
  border-radius: 3px;
  background-color: #f0f0f0;
  border: solid 1px #d6d6d6;
  padding: 3px 16px;
  margin: 15px 0 20px;

  &.small {
    max-height: 185px;
    @include breakpoint($mid-breakpoint) {
      max-height: 135px;
    }
  }

  &.large {
    max-height: 400px;
    @include breakpoint($mid-breakpoint) {
      max-height: 550px;
    }
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
