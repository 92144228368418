@use "sass:math";

// -------------------------------
// Modal Variables
// to be moved to a variables file at a later time
// -------------------------------

$modal-width: 500px;

// -------------------------------
// Close Modal Button Rules
// -------------------------------

.modal__close {
  display: block;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAAlwSFlzAAALEwAACxMBAJqcGAAABCJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDUuNC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6dGlmZj0iaHR0cDovL25zLmFkb2JlLmNvbS90aWZmLzEuMC8iCiAgICAgICAgICAgIHhtbG5zOmV4aWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20vZXhpZi8xLjAvIgogICAgICAgICAgICB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iCiAgICAgICAgICAgIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyI+CiAgICAgICAgIDx0aWZmOlJlc29sdXRpb25Vbml0PjI8L3RpZmY6UmVzb2x1dGlvblVuaXQ+CiAgICAgICAgIDx0aWZmOkNvbXByZXNzaW9uPjU8L3RpZmY6Q29tcHJlc3Npb24+CiAgICAgICAgIDx0aWZmOlhSZXNvbHV0aW9uPjcyPC90aWZmOlhSZXNvbHV0aW9uPgogICAgICAgICA8dGlmZjpPcmllbnRhdGlvbj4xPC90aWZmOk9yaWVudGF0aW9uPgogICAgICAgICA8dGlmZjpZUmVzb2x1dGlvbj43MjwvdGlmZjpZUmVzb2x1dGlvbj4KICAgICAgICAgPGV4aWY6UGl4ZWxYRGltZW5zaW9uPjI2PC9leGlmOlBpeGVsWERpbWVuc2lvbj4KICAgICAgICAgPGV4aWY6Q29sb3JTcGFjZT4xPC9leGlmOkNvbG9yU3BhY2U+CiAgICAgICAgIDxleGlmOlBpeGVsWURpbWVuc2lvbj4yNjwvZXhpZjpQaXhlbFlEaW1lbnNpb24+CiAgICAgICAgIDxkYzpzdWJqZWN0PgogICAgICAgICAgICA8cmRmOkJhZy8+CiAgICAgICAgIDwvZGM6c3ViamVjdD4KICAgICAgICAgPHhtcDpNb2RpZnlEYXRlPjIwMTctMDctMTlUMTM6MDc6OTY8L3htcDpNb2RpZnlEYXRlPgogICAgICAgICA8eG1wOkNyZWF0b3JUb29sPlBpeGVsbWF0b3IgMy42PC94bXA6Q3JlYXRvclRvb2w+CiAgICAgIDwvcmRmOkRlc2NyaXB0aW9uPgogICA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgplBNPKAAAA/ElEQVRIDcWWaw6DMAyDq0lod+ZU+7XrMbs0EERfLoJFCi0lzteGSBCCs2VZJvgMf7tlaUptyjFlhXhIyAdO+8JlGDVJiyHmOsKw6CEMokkwxHtITIALN77COEkLGE7WBYMqB7FkKwx3fCc1q8IgrEEs7xw6A7MwWSsL0E4jmtiFilCJLbV4T71ZRnrNsqU+QKHugV2DGPECrH0Sg9g4ANMhA7Am5GVJ/zY+UroBCCTRmiXcKofw+9u7E8Jd02tWPhlUPSeJCZTYrVScjAhljSxwW5S0CH7sw/fMpzy9oxys3DmufDYtlHH/OXGBHiZBXA7fuWeICyTslh/IH/fDFKMITynfAAAAAElFTkSuQmCC');
  background-size: 15px 15px;
  height: 15px;
  width: 15px;
  position: absolute;
  top: 25px;
  right: 40px;
}

// -------------------------------
// Modal Rules
// -------------------------------

// Need to move some of these rules over to HTML classes
// using the layout utilities.

.modal-container,
.modal_container {
  position: relative;
  @include container(500px);

  &::after {
    clear: both;
    content: "";
    display: table;
  }
}

.modal {
  position: absolute;
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  border-radius: $global-border-radius;
  margin: 50px auto;
  box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.10);
  z-index: 3;
  padding: 0;
  @include span(12 of 12);

  &::after {
    clear: both;
    content: '';
    display: table;
  }

  p {
    &.block-img {
      img{
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
}


.modal__background {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
}

.modal__background__close {
  width: 100%;
  height: 100%;
  background: rgba(250, 250, 250, .75);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.modal__header {
  border-top-left-radius: $global-border-radius;
  border-top-right-radius: $global-border-radius;
  position: relative;
  background: $am_mint;
  padding: 20px 40px;
  min-height: 55px;
  h2,
  .header {
    color: #FFF;
    max-width: 420px;
    font-size: math.div(21,16)rem;
    line-height: 1.2em;
    margin: 0;
  }

  h2{
    width: 270px;
    @include breakpoint($mid-breakpoint) {
      width: 420px;
    }
  }
}

.modal__content {
  padding: 20px 40px 40px 40px;
  // width: $modal-width;
  &.modal__content--fixed {
    position: fixed;
  }
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}

.modal__app {

}

.modal__app__link {
  display: block;
  width: 100%;
  padding: 10px;
  color: $black;
  text-decoration: none;
  border-radius: 3px;
  &:hover {
    background: $lighter-gray;
  }
}


.modal__app__icon {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  overflow: hidden;
}

.modal__app__text {
  margin-top: 9px;
  margin-left: 10px;
}
