
.faq__sidebar {
  @include span(10 of 10);
  @include breakpoint($mid-breakpoint) {
    @include span(3 of 10);
  }
}

.faq__content {
  @include span(10 of 10);
  margin-top: 30px;
  @include breakpoint($mid-breakpoint) {
    margin-top: 0;
    @include span(last 7 of 10);
  }
}

.faq__answer ol,
.faq__answer ul {
  padding-left: 25px;
}
