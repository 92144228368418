// Box sizing rules for the grid

html {
  box-sizing: border-box;
  height: 100%;

  body,
  #root {
    height: 100%;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

// grid
.span8of12 {
  @include span(12 of 12);
  @include breakpoint($mid-breakpoint) {
    @include span(9 of 12);
  }
}

.span4of12 {
  @include span(12 of 12);
  @include breakpoint($mid-breakpoint) {
    @include span(last 3 of 12);
  }
}

.grid-column-two-thirds {
  width: 66.66666667%;
  float: left;
  padding: 0px 16px;
}

@media (max-width: $high-breakpoint) {
  .grid-column-two-thirds {
    width: 100% !important;
  }
  .grid-column-one-third {
    width: 100% !important;
  }
}

.grid-column-one-third {
  width: 33.3333333%;
  float: left;
  padding: 0px 16px;
}

.grid-row {
  margin-left: -16px;
  margin-right: -16px;
}
.grid-row:after {
  clear: both;
  content: '';
  display: block;
}
