.container {
  @include container(960px);
  width: auto;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.skip-link {
  &:active,
  &:focus {
    border-radius: 4px;
    position: absolute;
    outline: 2px solid #162d6d;
    outline-offset: 4px;
    background: white;
    clip: unset;
    width: auto;
    height: auto;
    padding: 0.5rem;
  }
}

progress.sr-only {
  width: 0% !important;
}
