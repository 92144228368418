
.milestones {

}

.milestone {
  margin-bottom: 10px;
}

.milestone__checkmark {
  display: inline-block;
  border-radius: 10px;
  background: $light-gray;
  width: 20px;
  height: 20px;
}

.milestone__checkmark__container {
  position: relative;
  width: 10%;
}

.milestone__checkmark__container span {
  position: absolute;
  top: -2px;
  left: 3px;
}

.milestone__text {
  width: 90%;
  position: relative;
  top: -3px;
}
