
.with-background {
  background: #FCFCFC;
  border-top: 1px solid #DEDEDE;
  border-bottom: 1px solid #DEDEDE;
}

.with-top-border {
  border-top: 1px solid $light-gray;
}

.with-bottom-border {
  border-bottom: 1px solid $light-gray;
}
