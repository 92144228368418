@use "sass:math";

.help-link {
  font-weight: bold;
  color: $dark-gray;
  border: 1px solid $dark-gray;
  text-decoration: none;
  font-size: math.div(9,16)rem;
  display: inline-block;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  text-align: center;
  line-height: 11px;
}

.compensation-table {
  background: $white;
  font-size: 1rem;
  list-style: none;
  border: 1px solid $border-gray;
  border-radius: $global-border-radius;
  padding: 0;
  li {
    @include clearfix;
    padding: 12px 10px 12px 15px;
    border-bottom: 1px solid $border-gray;

    span.left {
      width: 74%;
      float: left;
      text-align: left;
    }
    span.right {
      width: 24%;
      float: right;
      text-align: right;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.compensation-description {
  color: $warm-grey;
  font-size: math.div(14,16)rem;
}

