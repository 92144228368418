// Import Bower stuff
@import '../../node_modules/normalize-scss/sass/_normalize.scss';
@import '../../node_modules/susy/sass/_susy.scss';
@import '../../node_modules/breakpoint-sass/stylesheets/_breakpoint.scss';
//@import "../bower_components/angular-tooltips/dist/angular-tooltips.css";

// Susy config
$susy: (
  columns: 10,
  gutters: 1,
);

// Import mixins
@import 'styles/global/mixins';

// Import variables
@import 'styles/global/variables';

// Utilities
@import 'styles/utilities/layout';
@import 'styles/utilities/typography';
@import 'styles/utilities/grid';

// Sections
@import 'styles/sections/education/content';
@import 'styles/sections/education/hero';
@import 'styles/sections/education/how-you-help';
@import 'styles/sections/education/call-to-action';
@import 'styles/sections/education/faq';
@import 'styles/sections/enrollment/content';
@import 'styles/sections/enrollment/informed-consent';
@import 'styles/sections/enrollment/progress-bar';
@import 'styles/sections/management/general';
@import 'styles/sections/management/compensation';
@import 'styles/sections/management/contributions';
@import 'styles/sections/management/milestones';
@import 'styles/sections/management/settings';
@import 'styles/sections/auth/content';

// Elements
@import 'styles/elements/buttons';
@import 'styles/elements/datepicker';
@import 'styles/elements/checkbox-selector';
@import 'styles/elements/requestEmr';
@import 'styles/elements/link';
@import 'styles/elements/navbar';
@import 'styles/elements/or-divider';

// Global
@import 'styles/global/layout';
@import 'styles/global/forms';
@import 'styles/global/loadingIndicator';
@import 'styles/global/modals/modal-base';
@import 'styles/global/modals/contact-modal';
@import 'styles/global/modals/information-modal';

html {
  -webkit-text-size-adjust: 100%;
}

body {
  background: #ffffff;
  margin: 0;
}

a button {
  @extend .tc-black;
}

a {
  cursor: pointer;
}

.br3 {
  border-radius: $global-border-radius;
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

@media (min-width: 810px) {
  #launcher-frame {
    bottom: 110px !important;
  }
}