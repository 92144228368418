.m-signature-pad {
  position: relative;
  width: 100%;
  height: 100px;
  background-color: #fff;
}

.m-signature-pad--body {
  width: 100%;
  height: 100%;
}

.m-signature-pad--body canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
}

.m-signature-pad--footer {
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 20px;
  height: 40px;
}

.m-signature-pad--footer .description {
  color: #C3C3C3;
  text-align: center;
  font-size: 1.2em;
  margin-top: 1.8em;
}

.m-signature-pad--footer .button {
  position: absolute;
  bottom: 0;
}

.m-signature-pad--footer .button.clear {
  left: 0;
}