@mixin clearfix($extend: true) {
  @if $extend {
    @extend %clearfix;
  } @else {
    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }
}

%clearfix {
  @include clearfix($extend: false);
}

.clearfix,
.clearFix {
  @include clearfix;
}