
.overview-steps {

}

.overview-step {

}

.overview-step__number-container {
  width: 13%;
}

.overview-step__number {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: 1px solid $light-gray;
  text-align: center;
  padding-top: 3px;
}

.overview-step__info {
  width: 87%;
}
