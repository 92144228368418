input,
select,
textarea {
  background: $input-background-color;
  color: $black;
  padding: 1rem;
  border: 1px solid $light-gray;
  border-radius: 3px;
  width: 100%;
  &:not([type='checkbox']),
  &:not([type='radio']) {
    font-size: 1rem;
  }
}

input.white {
  background: white;
}

input[type='radio'] {
  vertical-align: baseline;
}

/* Remove the spin buttons added to number inputs */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

input[type='radio'],
input[type='checkbox'] {
  padding: initial;
  border: 1px solid #ccc;
  border-radius: 0;
  /* PRSM-2262: Safari display issues with max-width: replacing with hardcoded width */
  width: 36px !important;
}

input[type='checkbox'] {
  height: 24px !important;
}

input[type='radio'] {
  border-radius: 50%;
  width: auto;
}

.evi--fieldset {
  width: 100%;

  & legend {
    & p {
      margin: 0px;
    }
  }
}

.evi--fieldset-error {
  padding-left: 1rem;
  border-left: 4px solid #B60000;
}

.evi--error-text {
  color: #B60000;
}

.error-summary--container {
  border: 4px solid #B60000;
  padding-left: 1rem;
}

.error-summary--title {
  font-size: 24px;
  font-weight: 600;
  color: #000;
}

.error-summary--item-container {
  list-style-type: none;
  padding-left: 0px;
}

.error-summary--item {
  margin-top: 8px;
}

.error-summary--item-link {
  color: #B60000;
  font-weight: bold;
}

.evi-error--border {
  border: 4px solid #B60000 !important;
}
