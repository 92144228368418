@use 'sass:color';

a.navbar--item,
button.navbar--item {
  opacity: 1;

  &:focus {
    border-radius: 4px;
    outline: 2px solid $primary-color;
  }
}

@media (min-width: 801px) {
  .ehp-mobile-menu--toggle {
    display: none !important;
  }
}
