.or-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
}


.or-container--border {
  border-bottom: 1px solid #A6A6A6;
  width: 100%;
}

.or-container--text {
  font-weight: 500;
  font-size: 15px;
  padding: 5px;
  color: #A6A6A6;
}