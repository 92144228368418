.enrollment-content {
  min-height: 500px;
  @include span(10 of 10);
  float: none;
  margin: auto;
  @include breakpoint($high-breakpoint) {
    @include span(8 of 10);
    float: none;
    margin: auto;
  }
}

legend > h1 {
  font-size: 26px;
  text-align: start;
}
