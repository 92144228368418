// Grid variables
$lower-breakpoint: 320px;
$mid-breakpoint: 641px;
$authed-header-breakpoint: 769px;
$high-breakpoint: 769px;

// AM Rebrand Colors: 2017
$background-gray: #f2f5f6;
$light-gray: #eff2f4;
$am-mint: #7ae1bf;
$dark-mint: #19bc81;
$am-red: #f43151;
$am-dark-red: #b21f36;
$am-magenta: #ff009e;
$am-navy: #06466d;
$am-blue: #75bbee;

// Colors
// Study colors
$primary-color: #06466d; // Currently Achievement pink
$secondary-color: #0893cf;
//
$white: #ffffff;
$black: #000;
$dark-gray: #999999;
$gray: #cccccc;
$light-gray: $light-gray;
$lighter-gray: $background-gray;
$lightest-gray: #fcfcfc;
$warm-grey: $dark-gray;
$disabled-grey: $gray;
$border-gray: #e0e0e0;
$red: #ef0000;
$green: #00c315;
$pink: #ef4490;
$light-pink: #f3afcd;
$blue: #1996f0;
$blue-dark: #1b2e36;
$slate: #5b6b7d;
$font-color: $black;

$input-background-color: $white;

// Typography
$font-stack: Helvetica, sans-serif;

$global-border-radius: 3px;
