@use "sass:color";

button, .button {
  border: 0;
}

a.button {
  display: block;
  text-align: center;
  line-height: 1.9em;
  text-decoration: none;
}

.button--primary,
.button--secondary,
.button--hollow {
  font-size: 1rem;
  background: $pink;
  border-radius: 3px;
  color: $white;
  padding: 14px 20px;
  height: 60px;
  width: 100%;
}

.button--primary {
  background-image: linear-gradient(to bottom, #f74595, #ef4490);
  border: solid 1px #db3d84;
  color: $white;
  &:hover {
    background-image: linear-gradient(to bottom, #f04391, #e8418c);
    border: solid 1px #d43b80;
  }
  &:active,
  &.active {
    background-image: linear-gradient(to top, #f74595, #ef4490);
    border: solid 1px #db3d84;
  }
  &:disabled,
  &:disabled:hover {
    background-image: linear-gradient(to bottom, #f74595, #ef4490);
    border: solid 1px #db3d84;
    color: #ffaccf;
  }
}

.button--secondary {
  background-image: linear-gradient(to bottom, #fafafa, #f5f5f5);
  border: solid 1px #e6e6e6;
  color: $black;
  &:hover {
    background-image: linear-gradient(to bottom, #f2f2f2, #ededed);
    border: solid 1px #dedede;
  }
  &:active,
  &.active {
    background-image: linear-gradient(to top, #fafafa, #f5f5f5);
    border: solid 1px #e6e6e6;
  }
  &:disabled,
  &:disabled:hover {
    background-image: linear-gradient(to bottom, #fafafa, #f5f5f5);
    border: solid 1px #e6e6e6;
    color: #a6a6a6;
  }
}

.button--hollow {
  border: 1px solid $light-gray;
  background: $lightest-gray;
  color: $gray;
  border-radius: 0;

  &:hover,
  &.active {
    color: $black;
  }

  &:disabled,
  &:disabled:hover {
    background: $lightest-gray;
    color: color.adjust($black, $red: 100, $green: 100, $blue: 100);
  }

  .float-left & {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    border-right: 0;
  }

  .float-right & {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }

}

// button[disabled],
// button[disabled]:hover {
//   background: $lighter-gray + 10;
//   color: $black + 100;
//   border: 1px solid $gray;
// }

label span {
  position: relative;
  top: 1px;
}

.button--group {
  &.float-left {
    button {
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px;
    }
  }
  &.float-right {
    button {
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
    }
  }
}
