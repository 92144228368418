
.clear {
  clear: both;
}

.display-block {
  display: block;
}

///////////////
// Width
///////////////

.w100p {
  width: 100%;
}

.w50p {
  width: 50%;
}

///////////////
// Padding
///////////////

.p3 {
  padding: 3px;
}

.p5 {
  padding: 5px;
}

.p10 {
  padding: 10px;
}

.p20 {
  padding: 20px;
}

.p30 {
  padding: 30px;
}

.pr20 {
  padding-right: 20px;
}

.pt0 {
  padding-top: 0px;
}

.pt5 {
  padding-top: 5px;
}

.pt10 {
  padding-top: 10px;
}

.pt12 {
  padding-top: 12px;
}

.pt30 {
  padding-top: 30px;
}

.pt50 {
  padding-top: 50px;
}

.pt100 {
  padding-top: 100px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb15 {
  padding-bottom: 15px;
}

.pb30 {
  padding-bottom: 30px;
}

.pb50 {
  padding-bottom: 50px;
}

.ptb5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.ptb15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.ptb20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.ptb40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.ptb100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.ptb50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.plr50 {
  padding-left: 50px;
  padding-right: 50px;
}

.plr30 {
  padding-left: 30px;
  padding-right: 30px;
}

.plr20 {
  padding-left: 20px;
  padding-right: 20px;
}
///////////////
// Margin
///////////////

.m0 {
  margin: 0;
}

.mb0 {
  margin-bottom: 0;
}

.mr0p {
  margin-right: 0%;
}

.mr4p {
  margin-right: 4%;
}

.mr10 {
  margin-right: 10px;
}

.mr20 {
  margin-right: 20px;
}

.mb0 {
  margin-bottom: 0;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mt0 {
  margin-top: 0px;
}

.mt5 {
  margin-top: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt25 {
  margin-top: 25px;
}

.mt27 {
  margin-top: 27px;
}

.mt30 {
  margin-top: 30px;
}

.mt50 {
  margin-top: 50px;
}

.mtb3 {
  margin-top: 3px;
  margin-bottom: 3px;
}

.mb100 {
  margin-bottom: 100px;
}

.ml5 {
  margin-left: 5px;
}

.ml10 {
  margin-left: 10px;
}

.ml20 {
  margin-left: 20px;
}

.mr10 {
  margin-right: 10px;
}

.mlr6 {
  margin-left: 6px;
  margin-right: 6px;
}