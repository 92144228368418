
.progress-bar {
  width: 100%;
  height: 16px;
  border-radius: 8px;
  overflow: hidden;
  background-color: #f7f7f7;
}

.progress-bar .progress-bar__fill {
   height: 100%;
   background-color: #b3b3b3;
   border-radius: 8px;
   -webkit-transition: width 0.3s ease;
   -moz-transition: width 0.3s ease;
   transition: width 0.3s ease;
}
