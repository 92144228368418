@use"sass:color";

.hero {
  background: color.adjust($light-gray, $red: 20, $green: 20, $blue: 20);
  background-position: top center;
  background-size: auto 810px;
  background-repeat: no-repeat;
}

.hero__content {
  @include span(10 of 10);
  float: none;
  margin: auto;
  @include span(10 of 10);
  @include breakpoint($lower-breakpoint) {
    @include span(last 8 of 10);
    float: none;
    margin: auto;
  }
  @include breakpoint($mid-breakpoint) {
    @include span(last 7 of 10);
    float: none;
    margin: auto;
  }
  @include breakpoint(900px) {
    @include span(last 6 of 10);
    float: none;
    margin: auto;
  }
}

.hero__buttons {
  @include span(10 of 10);
  @include breakpoint($lower-breakpoint) {
    @include span(last 9 of 10);
    float: none;
    margin: auto;
  }
  @include breakpoint($mid-breakpoint) {
    @include span(last 7 of 10);
    float: none;
    margin: auto;
  }
  @include breakpoint(900px) {
    @include span(last 6 of 10);
    float: none;
    margin: auto;
  }
}

.hero__disabled-banner {
  background: $am-mint;
  color: $white;

  a {
    color: $white;

    &:hover {

    }
  }
}
