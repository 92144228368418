.management-main {
  margin-top: 0;

  @include span(10 of 10);

  @include breakpoint($high-breakpoint) {
    @include span(8 of 12);
  }

  @include breakpoint($mid-breakpoint) {
    padding-right: 60px;
  }

  .contribution-spacer {
    margin-bottom: 20px;
  }

  ol,
  ul {
    li {
      margin-bottom: 0 !important;
    }
  }

  li {
    &:last-child {
      // margin-bottom: -30px;

      > div {
        // margin-bottom: -4px !important;
      }

      .contribution-spacer {
        // margin-bottom: 0;
      }
    }
  }

  &.expanded {
    &:before {
      margin-bottom: 30px; // required to pull the line above where it would be visible on the final milestone
    }
  }
}

.management-sidebar {
  @include span(10 of 10);
  padding-top: 50px;
  @include breakpoint($high-breakpoint) {
    @include span(last 4 of 12);
    padding-top: 0;
  }
}
