
.call-to-action__content {
  @include span(10 of 10);
  @include breakpoint($high-breakpoint) {
    @include span(6 of 10);
  }
}

.call-to-action__button {
  @include span(10 of 10);
  margin-top: 20px;
  @include breakpoint($high-breakpoint) {
    margin-top: 0;
    @include span(last 3 of 10);
  }
}
