.button--primary {
  // instances where we're using the loading indicator inside of a button
  .spinner {
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
    top: 2px;
    > div {
      width: 13px;
      height: 13px;
      background-color: #fff;
    }
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
