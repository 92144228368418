
table.chosen-contact-method {
  width: 100%;
}

table.chosen-contact-method,
.chosen-contact-method td,
.chosen-contact-method th {
  border: 1px solid $light-gray;
  padding: 15px;
}
